import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { So5LeaderboardSeasonality } from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';

const Wrapper = styled(Vertical)`
  padding: var(--double-unit);
  border-radius: var(--double-unit);
  background: rgba(var(--c-rgb-neutral-200), 0.3);
  border: 1px solid var(--c-neutral-400);
  position: relative;

  &.IN_SEASON {
    & > * {
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: var(--double-unit);
      border: 1px solid transparent;
      background: linear-gradient(
          84deg,
          #f8d3da 0%,
          #b3a9f4 28.32%,
          #fbe9fb 54.01%,
          #4f94fd 100%
        )
        border-box;
      mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
  }
`;

const Content = styled.div`
  flex: 1;
`;

type Props = {
  header: ReactNode;
  children: ReactNode;
  seasonality?: So5LeaderboardSeasonality | null;
};

export const CompetitionItem = ({ children, header, seasonality }: Props) => {
  return (
    <Wrapper gap={2} className={classNames(seasonality)}>
      <Horizontal>{header}</Horizontal>
      <Content>{children}</Content>
    </Wrapper>
  );
};

import { TypedDocumentNode, gql } from '@apollo/client';

import { GameStatus, Sport } from '@sorare/core/src/__generated__/globalTypes';
import { ANY_SPORT_TEAMS_SLUG } from '@sorare/core/src/constants/routes';
import { isType, withFragments } from '@sorare/core/src/gql';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import { GenericGame } from 'components/seo/Games/Game';

import { gameToGenericGame_anyGameInterface } from './__generated__/game.graphql';

const sportPerGameType = {
  Game: Sport.FOOTBALL,
  GameOfBasketball: Sport.NBA,
  GameOfBaseball: Sport.BASEBALL,
};

export const gameToGenericGame = withFragments(
  ({
    game,
    onClick,
  }: {
    game: gameToGenericGame_anyGameInterface;
    onClick?: (id: string) => void;
  }): GenericGame => {
    const matchLiveOrPlayed = [GameStatus.played, GameStatus.playing].includes(
      game.status
    );

    const sport = sportPerGameType[game.__typename];

    const homeTeam = game.homeTeam
      ? {
          ...game.homeTeam,
          to: isType(game.homeTeam, 'Club')
            ? generateSportPath(ANY_SPORT_TEAMS_SLUG, {
                sport,
                params: { slug: game.homeTeam?.slug },
              })
            : undefined,
        }
      : null;

    const awayTeam = game.awayTeam
      ? {
          ...game.awayTeam,
          to: isType(game.awayTeam, 'Club')
            ? generateSportPath(ANY_SPORT_TEAMS_SLUG, {
                sport,
                params: { slug: game.awayTeam?.slug },
              })
            : undefined,
        }
      : null;

    return {
      id: game.id,
      date: game.date,
      gameWeek: game.so5Fixture?.gameWeek,
      homeTeam:
        isType(game, 'GameOfBaseball') || isType(game, 'GameOfBasketball')
          ? awayTeam
          : homeTeam,
      awayTeam:
        isType(game, 'GameOfBaseball') || isType(game, 'GameOfBasketball')
          ? homeTeam
          : awayTeam,
      homeScore: game.homeScore || 0,
      awayScore: game.awayScore || 0,
      competition: {
        ...game.competition,
        name: game.competition.displayName,
      },
      status: game.status,
      onClick: matchLiveOrPlayed ? () => onClick?.(game.id) : undefined,
      coverageStatus: isType(game, 'Game') ? game.coverageStatus : undefined,
    };
  },
  {
    anyGameInterface: gql`
      fragment gameToGenericGame_anyGameInterface on AnyGameInterface {
        id
        date
        status: statusTyped
        competition {
          slug
          displayName
        }
        homeTeam {
          slug
          name
          pictureUrl
        }
        homeScore
        awayTeam {
          slug
          name
          pictureUrl
        }
        awayScore
        so5Fixture {
          slug
          gameWeek
        }
        ... on Game {
          id
          coverageStatus
        }
      }
    ` as TypedDocumentNode<gameToGenericGame_anyGameInterface>,
  }
);

import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { LinkOther } from 'atoms/navigation/Box';
import { LabelM, LabelS } from 'atoms/typography';
import Avatar from 'components/user/Avatar';
import { useCurrentUserContext } from 'contexts/currentUser';
import { hideScrollbar } from 'style/utils';

import { CompetitionRanking_User } from './__generated__/index.graphql';

const Wrapper = styled.div`
  ${hideScrollbar}
  display: grid;
  grid-template-columns: max-content var(--triple-unit) 1fr;
  column-gap: var(--unit);
  row-gap: var(--double-unit);
  align-items: start;
  overflow: auto;
  --mask-width: var(--double-unit);
  margin: 0 calc(-1 * var(--mask-width));
  padding: 0 var(--mask-width) var(--unit);
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black var(--mask-width),
    black calc(100% - var(--mask-width)),
    transparent 100%
  );
`;

const Row = styled.div`
  display: grid;
  grid-template-areas: 'rank user user' '. . rewards';
  grid-template-columns: subgrid;
  grid-column: span 3;
  align-items: center;
  justify-items: start;
  @supports not (grid-template-columns: subgrid) {
    grid-template-columns: max-content var(--triple-unit) 1fr;
    column-gap: var(--unit);
  }
`;

const Rank = styled(LabelS).attrs({ as: 'p' })`
  grid-area: rank;
  width: 100%;
  min-width: calc(5 * var(--unit));
  color: var(--c-neutral-600);
  text-align: end;
`;

const RankingUser = styled(LinkOther)`
  grid-area: user;
  display: flex;
  align-items: center;
  gap: var(--unit);
  &:hover,
  &:focus {
    color: var(--c-neutral-700);
  }

  &.disabled:hover,
  &.disabled:focus {
    color: inherit;
    cursor: default;
  }
`;

const Nickname = styled(LabelM).attrs({ as: 'span' })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Rewards = styled(Horizontal)`
  grid-area: rewards;
  font: var(--t-12);
  color: var(--c-neutral-600);
`;

export const CompetitionRanking = <
  T extends {
    id: string;
    lineup: { user: CompetitionRanking_User };
  },
>({
  rewards,
  onClick,
  rankings,
  myBestRanking,
}: {
  onClick?: (id: string) => void;
  rewards?: (ranking: T) => ReactNode;
  myBestRanking?: {
    id: string;
    ranking: number | null;
    fullRanking?: T;
  };
  rankings: T[];
}) => {
  const { currentUser } = useCurrentUserContext();
  return (
    <Wrapper>
      {rankings.map((ranking, index) => {
        const { user } = ranking.lineup;
        return (
          <Row key={ranking.id}>
            <Rank>#{index + 1}</Rank>
            <RankingUser
              onClick={() => onClick?.(ranking.id)}
              className={classNames({ disabled: !onClick })}
            >
              <Avatar user={user} rounded variant="small" />
              <Nickname>{user.nickname}</Nickname>
            </RankingUser>
            <Rewards>{rewards?.(ranking)}</Rewards>
          </Row>
        );
      })}
      {myBestRanking && currentUser && (myBestRanking.ranking || 0) > 3 && (
        <Row>
          <Rank>#{myBestRanking.ranking}</Rank>
          <RankingUser
            onClick={() => onClick?.(myBestRanking.id)}
            className={classNames({ disabled: !onClick })}
          >
            <Avatar user={currentUser} rounded variant="small" />
            <Nickname>
              <FormattedMessage
                id="CompetitionRanking.you"
                defaultMessage="You"
              />
            </Nickname>
          </RankingUser>
          {myBestRanking.fullRanking && (
            <Rewards>{rewards?.(myBestRanking.fullRanking)}</Rewards>
          )}
        </Row>
      )}
    </Wrapper>
  );
};

CompetitionRanking.fragments = {
  User: gql`
    fragment CompetitionRanking_User on User {
      slug
      nickname
      ...Avatar_user
    }
    ${Avatar.fragments.user}
  ` as TypedDocumentNode<CompetitionRanking_User>,
};

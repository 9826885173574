import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import {
  ChangeEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { CSSProperties, css } from 'styled-components';

import { Currency, SupportedCurrency } from '__generated__/globalTypes';
import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
// eslint-disable-next-line import/no-restricted-paths
import { useIntlContext } from 'contexts/intl';
import useMonetaryAmount, {
  MonetaryAmountOutput,
} from 'hooks/useMonetaryAmount';
import useRegexp from 'hooks/useRegexp';
import { CurrencyCode, currencySymbol } from 'lib/fiat';
import { getFiatMonetaryAmountIndex } from 'lib/monetaryAmount';
import { RoundingMode, fromWei, toWei } from 'lib/wei';

const Label = styled.label<{
  $error?: boolean;
}>`
  position: relative;
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  cursor: text;
  font: var(--input-font, var(--t-bold) var(--t-48));
  color: var(--color);
  z-index: 0;
  ${({ $error }) =>
    $error
      ? css`
          --color: var(--c-red-600);
        `
      : css`
          --color: var(--input-color, var(--c-brand-600));
        `};
  &:before {
    content: attr(data-before);
    margin-right: var(--unit);
    display: block;
    visibility: hidden;
  }
  &:after {
    content: attr(data-after);
    margin-left: var(--unit);
    display: block;
    visibility: hidden;
  }
`;

const Placeholder = styled.span`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: transparent;
  &:before {
    content: attr(data-before);
    margin-right: var(--unit);
    display: block;
    color: var(--color);
  }
  &:after {
    content: attr(data-after);
    display: block;
    margin-left: var(--unit);
    color: var(--color);
  }
`;

const PlaceholderAmount = styled.span<{ background?: boolean }>`
  ${({ background }) =>
    background
      ? css`
          padding: var(--unit) var(--unit);
          border-radius: var(--double-unit);
          background-color: var(--c-neutral-400);
          z-index: -1;
        `
      : css`
          visibility: hidden;
        `}
`;

const StyledInput = styled.input`
  width: 100%;
  display: inline-flex;
  text-align: center;
  font: inherit;
  border: none;
  background-color: transparent;
  color: var(--color);
  line-height: 100%;
`;

const CenteredInputWithSymbol = forwardRef<
  HTMLInputElement,
  {
    value: string;
    symbol: string;
    symbolBefore?: string;
    placeholder?: string;
    disabled?: boolean;
    ariaLabel: string;
    error?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    background?: boolean;
  }
>(
  (
    {
      value,
      symbol,
      symbolBefore,
      placeholder,
      disabled,
      ariaLabel,
      onChange,
      error,
      autoFocus,
      background,
    },
    ref
  ) => (
    <Label data-before={symbolBefore} data-after={symbol} $error={error}>
      <StyledInput
        ref={ref}
        placeholder={placeholder}
        value={value}
        type="text"
        onChange={onChange}
        disabled={disabled}
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        inputMode="decimal"
      />
      <Placeholder data-before={symbolBefore} data-after={symbol}>
        <PlaceholderAmount background={background}>
          {value || placeholder}
        </PlaceholderAmount>
      </Placeholder>
    </Label>
  )
);
CenteredInputWithSymbol.displayName = 'CenteredInputWithSymbol';

export type Props = {
  defaultValue: MonetaryAmountOutput;
  fiatCurrency: CurrencyCode;
  defaultCurrency?: Currency;
  onlyShowFiatCurrency: boolean;
  onChange: (
    monetaryAmount: MonetaryAmountOutput,
    target: HTMLInputElement,
    referenceCurrency: SupportedCurrency
  ) => void;
  onToggleEthDisplay?: (ethDisplay: boolean) => void;
  placeholder?: string;
  error?: ReactNode;
  disabled?: boolean;
  setOutBidCallback?: (callback: (amount: string) => void) => void;
  autoFocus?: boolean;
  controlledEthDisplay?: boolean;
  useSecondaryInput?: boolean;
};

export const MonetaryInput = ({
  defaultValue,
  controlledEthDisplay,
  fiatCurrency,
  onChange,
  defaultCurrency,
  placeholder,
  onToggleEthDisplay,
  setOutBidCallback,
  onlyShowFiatCurrency,
  error,
  disabled,
  autoFocus = true,
  useSecondaryInput = false,
}: Props) => {
  const { formatWei, formatNumber } = useIntlContext();
  const [monetaryAmount, setMonetaryAmount] =
    useState<MonetaryAmountOutput>(defaultValue);
  const { toMonetaryAmount } = useMonetaryAmount();
  const ref = useRef<HTMLInputElement>(null);
  const monetaryAmountIndex = getFiatMonetaryAmountIndex(fiatCurrency);

  const [ethDisplay, setEthDisplay] = useState(
    !onlyShowFiatCurrency && defaultCurrency === Currency.ETH
  );

  useEffect(() => {
    setEthDisplay(!onlyShowFiatCurrency && defaultCurrency === Currency.ETH);
  }, [defaultCurrency, onlyShowFiatCurrency, setEthDisplay]);

  const ethAmount = fromWei(
    monetaryAmount.wei,
    4,
    RoundingMode.ROUND_DOWN
  ).toString();
  const ethAmountStr = formatWei(monetaryAmount.wei, undefined, {
    maximumFractionDigits: 4,
  });
  const fiatAmount = (monetaryAmount[monetaryAmountIndex] / 100).toString();
  const fiatAmountStr = formatNumber(
    monetaryAmount[monetaryAmountIndex] / 100,
    {
      style: 'currency',
      currency: fiatCurrency,
      maximumFractionDigits: 2,
    }
  );
  const [input, setInput] = useState<string>(
    ethDisplay ? ethAmount : fiatAmount
  );

  const [secondaryInput, setSecondaryInput] = useState<string>(
    !ethDisplay ? ethAmount : fiatAmount
  );

  const ethDecimalRegexp = useRegexp(`^([0-9]+)?([.,][0-9]{0,4})?$`);

  const fiatDecimalRegexp = useRegexp(`^([0-9]+)?([.,][0-9]{0,2})?$`);

  const onlyDotsOrCommasRegexp = useRegexp(`^[.,]+$`);

  const toggleDisplayedCurrency = useCallback(() => {
    if (onToggleEthDisplay) onToggleEthDisplay(!ethDisplay);
    setEthDisplay(prevEthDisplay => !prevEthDisplay);
    setInput(ethDisplay ? fiatAmount : ethAmount);
    setSecondaryInput(!ethDisplay ? fiatAmount : ethAmount);
  }, [ethAmount, ethDisplay, fiatAmount, onToggleEthDisplay]);

  useEffect(() => {
    if (
      controlledEthDisplay !== undefined &&
      ethDisplay !== controlledEthDisplay
    ) {
      toggleDisplayedCurrency();
    }
  }, [controlledEthDisplay, ethDisplay, toggleDisplayedCurrency]);

  const handle = useCallback(
    (value: string, isSecondaryInput?: boolean) => {
      const inputIsEth = isSecondaryInput ? !ethDisplay : ethDisplay;
      if (
        inputIsEth
          ? ethDecimalRegexp.test(value)
          : fiatDecimalRegexp.test(value)
      ) {
        if (isSecondaryInput) {
          setSecondaryInput(value);
        } else {
          setInput(value);
        }
        const formattedAmount = Number.parseFloat(
          onlyDotsOrCommasRegexp.test(value)
            ? '0'
            : `${value.replace(',', '.')}` || '0'
        );
        const referenceCurrency = inputIsEth
          ? SupportedCurrency.WEI
          : SupportedCurrency[fiatCurrency];
        const amount = inputIsEth
          ? toWei(formattedAmount)
          : Math.round(formattedAmount * 100);
        const inputMonetaryParams = {
          referenceCurrency,
          [referenceCurrency.toLowerCase()]: amount,
        };
        const newMonetaryAmount = toMonetaryAmount(inputMonetaryParams);

        setMonetaryAmount(newMonetaryAmount);
        onChange(
          newMonetaryAmount,
          ref.current!,
          inputIsEth ? SupportedCurrency.WEI : SupportedCurrency[fiatCurrency]
        );
        const convertedAmount = inputIsEth
          ? (newMonetaryAmount[monetaryAmountIndex] / 100).toString()
          : fromWei(newMonetaryAmount.wei, 4).toString();
        if (isSecondaryInput) {
          setInput(convertedAmount);
        } else {
          setSecondaryInput(convertedAmount);
        }
      }
    },
    [
      ethDisplay,
      ethDecimalRegexp,
      fiatDecimalRegexp,
      onlyDotsOrCommasRegexp,
      fiatCurrency,
      toMonetaryAmount,
      onChange,
      monetaryAmountIndex,
    ]
  );

  const handler = (event: ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.value;
    handle(amount);
  };

  const handlerSecondary = (event: ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.value;
    handle(amount, true);
  };

  useEffect(() => {
    if (setOutBidCallback) {
      setOutBidCallback(() => {
        return (amount: string) => {
          const ethAmountValue = fromWei(
            amount,
            4,
            RoundingMode.ROUND_DOWN
          ).toString();
          handle(ethDisplay ? ethAmountValue : fiatAmountStr);
        };
      });
    }
  }, [ethDisplay, fiatAmountStr, handle, setOutBidCallback]);

  return (
    <Vertical gap={1} center>
      <Vertical gap={0} center>
        <CenteredInputWithSymbol
          ref={ref}
          value={input}
          onChange={handler}
          symbol={ethDisplay ? 'ETH' : ''}
          symbolBefore={ethDisplay ? '' : currencySymbol(fiatCurrency)}
          placeholder={placeholder}
          ariaLabel={ethDisplay ? 'ether' : 'fiat'}
          disabled={disabled}
          error={!!error}
          autoFocus={autoFocus}
        />
        {error}
      </Vertical>
      {!onlyShowFiatCurrency && (
        <Horizontal>
          {useSecondaryInput ? (
            <div
              style={
                {
                  '--input-font': '16px',
                  '--input-color': 'var(--c-neutral-700)',
                } as CSSProperties
              }
            >
              <CenteredInputWithSymbol
                onChange={handlerSecondary}
                value={secondaryInput}
                symbol={ethDisplay ? currencySymbol(fiatCurrency) : 'ETH'}
                symbolBefore="≈"
                placeholder="0"
                ariaLabel="secondaryInput"
                background
              />
            </div>
          ) : (
            <>
              <LabelM color="var(--c-neutral-700)">
                ≈&nbsp;
                {ethDisplay ? fiatAmountStr : ethAmountStr}
              </LabelM>
              <IconButton
                stroke
                smaller
                color="tertiary"
                aria-label="switch"
                onClick={toggleDisplayedCurrency}
                disableDebounce
              >
                <FontAwesomeIcon icon={faRepeat} size="xs" />
              </IconButton>
            </>
          )}
        </Horizontal>
      )}
    </Vertical>
  );
};

export default MonetaryInput;

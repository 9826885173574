import { useLocation } from 'react-router-dom';

import {
  FOOTBALL_PLAY_RIVALS_DRAFT_SLUG,
  FOOTBALL_PLAY_RIVALS_ONBOARDING_CLUB_SELECTION,
} from '@sorare/core/src/constants/__generated__/routes';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import useLifecycle, { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

const ONBOARDING_STEPS: string[] = [
  FOOTBALL_PLAY_RIVALS_ONBOARDING_CLUB_SELECTION,
  FOOTBALL_PLAY_RIVALS_DRAFT_SLUG,
];

export const useRivalsOnboarding = () => {
  const {
    flags: { enableNewRivalsOnboarding = false },
  } = useFeatureFlags();
  const { update, lifecycle } = useLifecycle();
  const location = useLocation();

  const currentStepIndex = ONBOARDING_STEPS.findIndex(
    path => path === location.pathname
  );

  return {
    onboardingEnabled: enableNewRivalsOnboarding,
    onboardingDone: lifecycle?.[LIFECYCLE.sawRivalsOnboarding],
    markOnboardingAsDone: () => update(LIFECYCLE.sawRivalsOnboarding, true),
    firstStep: ONBOARDING_STEPS[0],
    previousStep: ONBOARDING_STEPS[Math.max(currentStepIndex - 1, 0)],
    nextStep:
      ONBOARDING_STEPS[Math.min(currentStepIndex + 1, ONBOARDING_STEPS.length)],
  };
};

import { ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@sorare/core/src/style/types';

import { GridDiv } from '../BackgroundAligned';

export const GridDivWithBorder = styled(GridDiv)<{
  borderColor: Color;
  children?: ReactNode;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /*
   This color is equivalent to background: rgba(var(--c-rgb-red-600), 0.15) with a backdrop-filter: blur(30px);
   Done this way because the browsers do not always handle well backdrop-filter + clip-path
  */
  background: #391c1a;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    --w: 2px;
    background: ${({ borderColor }) => borderColor};
    clip-path: polygon(
      50% 0,
      100% 50%,
      50% 100%,
      0 50%,
      var(--w) 50%,
      50% calc(100% - var(--w)),
      calc(100% - var(--w)) 50%,
      50% var(--w),
      var(--w) 50%,
      0 50%,
      50% 0
    );
  }
`;

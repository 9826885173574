import { FOOTBALL_PLAY_RIVALS_ARENA } from '@sorare/core/src/constants/__generated__/routes';
import { Navigate } from '@sorare/core/src/routing/Navigate';

import { Onboarding } from 'components/rivals/Onboarding';
import { useRivalsOnboarding } from 'components/rivals/Onboarding/useRivalsOnboarding';

const OnboardingClubSelectionPage = () => {
  const { onboardingDone, onboardingEnabled } = useRivalsOnboarding();

  if (onboardingEnabled && !onboardingDone) {
    return <Onboarding />;
  }

  return <Navigate to={FOOTBALL_PLAY_RIVALS_ARENA} replace />;
};
export default OnboardingClubSelectionPage;

import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import LoadingMoreButton from '@sorare/core/src/atoms/buttons/LoadMoreButton';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import Bid from '../Bid';
import { BidHistory_tokenBidConnection } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 2 })``;

const Button = styled.div`
  align-self: center;
  padding-bottom: var(--double-unit);
`;

interface Props {
  loadMoreBids: (
    reload: boolean,
    variable: { bidCursor: string }
  ) => Promise<unknown>;
  bids: BidHistory_tokenBidConnection;
  loading: boolean;
  skipFirst?: boolean;
  displayAbsoluteDate?: boolean;
  showLoadMore?: boolean;
}

const BidHistory = ({
  bids,
  loading,
  loadMoreBids,
  skipFirst = false,
  showLoadMore = true,
  displayAbsoluteDate = false,
}: Props) => {
  const { hasNextPage, endCursor } = bids.pageInfo;

  if (bids.nodes.length === 0) return null;

  const list = skipFirst ? bids.nodes.slice(1) : bids.nodes;

  return (
    <Root>
      <div>
        {list.map(bid => (
          <Bid
            bid={bid}
            key={bid.id}
            displayAbsoluteDate={displayAbsoluteDate}
          />
        ))}
      </div>
      {hasNextPage && showLoadMore && (
        <Button>
          <LoadingMoreButton
            hasMore={hasNextPage}
            loading={loading}
            loadMore={() => {
              loadMoreBids(false, { bidCursor: endCursor! });
            }}
          />
        </Button>
      )}
    </Root>
  );
};

BidHistory.fragments = {
  bid: gql`
    fragment BidHistory_tokenBidConnection on TokenBidConnection {
      nodes {
        id
        ...Bid_tokenBid
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    ${Bid.fragments.tokenBid}
  ` as TypedDocumentNode<BidHistory_tokenBidConnection>,
};

export default BidHistory;

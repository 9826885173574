import { TypedDocumentNode, gql } from '@apollo/client';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';

import BidHistory from 'components/auction/BidHistory';

import {
  AuctionOwnershipDetailsQuery,
  AuctionOwnershipDetailsQueryVariables,
} from './__generated__/index.graphql';

interface Props {
  auction: { id: string };
  skipFirst?: boolean;
  showLoadMore?: boolean;
}

const AUCTION_OWNERSHIP_DETAILS_QUERY = gql`
  query AuctionOwnershipDetailsQuery($id: String!, $bidCursor: String) {
    tokens {
      auction(id: $id) {
        id
        bids(first: 5, after: $bidCursor) {
          ...BidHistory_tokenBidConnection
        }
      }
    }
  }
  ${BidHistory.fragments.bid}
` as TypedDocumentNode<
  AuctionOwnershipDetailsQuery,
  AuctionOwnershipDetailsQueryVariables
>;

export const AuctionOwnershipDetails = ({
  auction,
  skipFirst = true,
  showLoadMore = true,
}: Props) => {
  const {
    data,
    loading,
    loadMore: loadMoreBids,
  } = usePaginatedQuery(AUCTION_OWNERSHIP_DETAILS_QUERY, {
    variables: {
      id: idFromObject(auction.id)!,
    },
    connection: 'TokenBidConnection',
  });

  if (!data) return <LoadingIndicator />;

  return (
    <BidHistory
      bids={data?.tokens.auction.bids}
      loadMoreBids={loadMoreBids}
      loading={loading}
      skipFirst={skipFirst}
      displayAbsoluteDate
      showLoadMore={showLoadMore}
    />
  );
};

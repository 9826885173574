import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { FC, ReactNode, createContext, useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  Currency,
  Sport,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import {
  OrderedPaymentMethod,
  PaymentProvider_paymentMethod,
  WalletPaymentMethod,
} from '@sorare/core/src/lib/paymentMethod';

import { PaymentProvider_auction } from '../PaymentProvider/__generated__/fragments.graphql';
import { DisposableCard } from '../PaymentProvider/useStripePayment';

export interface OptionConfig {
  value: OrderedPaymentMethod;
  labelContent: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  getFees?: () => number;
  onSubmit?: () => void;
  onChange?: () => void;
  button?: ReactNode;
  buttonProps?: {
    disabled?: boolean;
  };
  Content?: FC<React.PropsWithChildren<{ isSelected: boolean }>>;
  pm?:
    | PaymentProvider_paymentMethod
    | DisposableCard
    | WalletPaymentMethod
    | StripePaymentMethod;
  topUpWalletWithCC?: boolean;
  Icon?: FC<React.PropsWithChildren<{ size: 'xs' | 'xl' }>>;
}

type PaymentContext = {
  objectId: string;
  auction?: PaymentProvider_auction;
  currencies: Currency[];
  cta: MessageDescriptor;
  defaultMonetaryAmount: MonetaryAmountOutput;
  monetaryAmount: MonetaryAmountOutput;
  updateMonetaryAmount: (
    amount: MonetaryAmountOutput,
    referenceCurrency: SupportedCurrency
  ) => void;
  fiatCurrency: string;
  paymentMethodsLoading: boolean;
  saveCreditCard: boolean;
  toggleSaveCreditCard: () => void;
  displayEth: boolean;
  setDisplayEth: (bool: boolean) => void;
  amountTooLow?: boolean;
  totalMonetaryAmount: MonetaryAmountOutput;
  feesMonetaryAmount: MonetaryAmountOutput;
  errors: (MessageDescriptor | string)[];
  warnings: (MessageDescriptor | string)[];
  paymentMethods: PaymentProvider_paymentMethod[] | null;
  fees: number;
  conversionCreditMonetaryAmount: MonetaryAmountOutput;
  conversionCredit?: ConversionCreditWithAmounts;
  conversionCredits?: ConversionCreditWithAmounts[];
  setConversionCredit: (cc: ConversionCreditWithAmounts | undefined) => void;
  showConversionCredit?: boolean;
  conversionCreditDisclaimer?: ReactNode;
  paymentCurrency: Currency | undefined;
  isFiat: boolean;
  sport: Sport;
  insufficientFundsInEthWallet: boolean;
  insufficientFundsInFiatWallet: boolean;
  referenceCurrency: SupportedCurrency;
  setReferenceCurrency: (c: SupportedCurrency) => void;
  initialAmountIsBellowStripeMinimum?: boolean;
  canChangeRefCurrency?: boolean;
  seller?: string;
  payWithConversionCreditsOnly: boolean;
  onlyCreditCards?: boolean;
  currencyAvailableOptions: OptionConfig[];
  selectedOptionConfig: OptionConfig | undefined;
  setPaymentCurrency: (c: Currency) => void;
  setSelectedOptionConfig: (pm: OrderedPaymentMethod) => void;
  setFeesRate: (fees: number) => void;
};

export const paymentContext = createContext<PaymentContext | null>(null);

export const usePaymentContext = () => useContext(paymentContext)!;

export default paymentContext.Provider;
